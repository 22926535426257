<template>
  <div>
    <el-dialog
      class="AddDialog"
      :title="tittle"
      :visible.sync="dialogVisible"
      width="800px"
      hegiht="1000px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        label-width="auto"
        class="d"
      >
        <el-row :gutter="15">
          <el-col :span="10">
            <el-form-item label="封面图" prop="img">
              <el-upload
                v-loading="loading"
                class="avatar-uploader"
                :action="this.$server.api + 'command/ossUpload?filename=file'"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                style="width: 178px"
              >
                <img v-if="ruleForm.img" :src="ruleForm.img" class="avatar" /><i
                  v-else
                  class="el-icon-plus avatar-uploader-icon"
                ></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="10" style="color:red">
              <span >首页封面图建议尺寸:360px *482px<br> 新闻资讯封面图建议尺寸:232px*127px</span>
          </el-col>
          <el-col :span="20">
            <el-form-item label="标题" prop="title">
              <el-input
                v-model="ruleForm.title"
                style="width: 180px"
                placeholder="请输入标题"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="副标题" prop="subhead">
              <el-input
                v-model="ruleForm.subhead"
                style="width: 540px"
                placeholder="请输入副标题"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="展示页面" prop="type">
              <el-select
               style="width: 180px"
                  v-model="ruleForm.type"
                  clearable
                  placeholder="请选择展示页面">
                <el-option label="首页" :value="1"></el-option>
                <el-option label="新闻资讯" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="20">
              <el-form-item prop="content" label="新闻详情">
                    <file-fu
                    v-model="ruleForm.content"
                    :isClear="isClear"
                    @change="change"
                  ></file-fu>
              </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!--  style="margin-left: 370px; margin-top: -100px" -->
      
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {newsAdd,newsEdit } from "@/request/api";
import { areaListData } from "@/utils/area";
import fileFu from './fileFu'
export default {
  name: "AddDialog",
  components: {fileFu},
  data() {
    return {
      isClear: false,
      id: "",
      tittle: "",
      dialogVisible: false,
      imageUrl: "",
      ruleForm: {
        img: "",
        title: "",
        subhead: "",
        content: "",
        type:""
      },
      content: "",
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        subhead: [{ required: true, message: "请输入副标题", trigger: "blur" }],
        content: [{ required: true, message: "请输入新闻内容", trigger: "blur" }],
        img: [{ required: true, message: "请选择图片", trigger: "blur" }],
        type: [{ required: true, message: "请选择展示页面", trigger: "change" }],
      },
      loading:false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    show(type, row) {
      this.dialogVisible = true;

      this.type = type;
      if (this.type == 2) {
        this.tittle = "编辑";

        this.id = row.id;
        this.imageUrl = row.img;
        this.ruleForm.img = row.img;
        this.ruleForm.title = row.title;
        this.ruleForm.subhead = row.subhead;
        this.ruleForm.content = row.content;
        this.ruleForm.type = row.type;

      } else {
        this.tittle = "添加";
      }
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      this.loading=true
      return isLt2M;
    },
    handleAvatarSuccess(res, file) {
      let imgurl = res.data;
      this.imageUrl = imgurl;
      const data = file.raw;
      // this.ruleForm.img = imgurl;
      // console.log(this.$server.api);
      // console.log(res.data);
      // console.log(res);
      // console.log(file);
      // 创建并填写Blob数据。
      //const data = new Blob(['Hello OSS']);
      // 创建并填写OSS Buffer内容。
      //const data = new OSS.Buffer(['Hello OSS']);
      this.putObject(data);

    },
    async putObject(data) {
      let OSS = require("ali-oss");
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
        region: "oss-cn-beijing",
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: "LTAI5tS4HMwn4NcLE9Hf2iUa",
        accessKeySecret: "wGxicSjxwjRbJzza84PplXTuySdA1l",
        // 从STS服务获取的安全令牌（SecurityToken）。
        // stsToken: "yourSecurityToken",
        // 填写Bucket名称。
        bucket: "yujianweilai02-tp5",
      });
      try {
        // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
        // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
        // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
        const result = await client.put(
          new Date().getTime() + ".jpg",
          data
          //{headers}
        );
        this.ruleForm.img = result.url;
        this.loading=false
        console.log(result, "1111111");

      } catch (e) {
        console.log(e);
      }
    },

    close() {
      this.dialogVisible = false;
      this.ruleForm.img = "";
      this.ruleForm.title = "";
      this.ruleForm.subhead = "";
        this.ruleForm.content = "";
        this.id = "";
      this.imageUrl = ""
        this.ruleForm.type=""
    },
    change(val) {
      this.form.content = val;
    },
    resetForm(ruleForm) {
      this.$refs[ruleForm].resetFields();
    },
    submitForm() {
      if (this.type == 1) {
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            let token = sessionStorage.getItem("token");
            this.token = token;
            let params = {
              img: this.ruleForm.img,
              title: this.ruleForm.title,
              subhead: this.ruleForm.subhead,
              content: this.ruleForm.content,
              type:this.ruleForm.type,

            };
            newsAdd(params).then((res) => {
              if (res.data.code == 200) {
                this.$message.success("新增成功");
                this.$parent.getUserList();
                this.close();
                this.isDisable = false;
              } else {
                this.$message.error(res.data.msg);
                this.$parent.getUserList();
                this.close();
                this.isDisable = false;
              }
            });
            this.resetForm()
          } else {
            return false;
          }
        });
      } else {
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            let params = {
              img: this.ruleForm.img,
              title: this.ruleForm.title,
              subhead: this.ruleForm.subhead,
              content: this.ruleForm.content,
              id: this.id,
              type:this.ruleForm.type,
            };
            newsEdit(params).then((res) => {
              if (res.data.code == 200) {
                this.$message.success("编辑成功");
                this.$parent.getUserList();
                this.close();
                this.isDisable = false;
              } else {
                this.$message.error(res.data.msg);
                this.$parent.getUserList();
                this.close();
                this.isDisable = false;
              }
            });
            this.resetForm()

          } else {
            return false;
          }
        });
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #352e2e;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.d {
  position: relative;
}
.d1 {
  position: absolute;
  top: 280px;
  left: 380px;
}
</style>
