<template>
  <div class="user">
    <div class="block-quote">
      <el-form :inline="true" :model="form" ref="form">
        <el-form-item label="标题">
            <el-input v-model="title" style="width: 180px"  placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="展示页面">
            <el-select
               style="width: 180px"
                  v-model="type"
                  clearable
                  placeholder="请选择展示页面">
                <el-option label="首页" :value="1"></el-option>
                <el-option label="新闻资讯" :value="2"></el-option>
              </el-select>
        </el-form-item>
        <el-form-item style="float: right">
          <el-button type="primary" @click="searchData()">搜索</el-button>
          <el-button
            type="primary"
            @click="addData()"
            style="margin-bottom: 10px"
            >添加</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <page-table
      ref="dataTable"
      :data="userList"
      @changeCurrentPage="changeCurrent"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            (page.currentPage - 1) * page.pageSize + scope.$index + 1
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题" align="center">
      </el-table-column>
      <el-table-column label="封面" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.img" min-width="70" height="70" />
        </template>
      </el-table-column>
      <el-table-column prop="subhead" label="副标题" align="center">
      </el-table-column>
      <el-table-column prop="type" label="展示页面" align="center">
        <template slot-scope="scope">
          <el-link v-if="scope.row.type==1">首页</el-link>
          <el-link v-if="scope.row.type==2">新闻资讯</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="editData(2, scope.row)" type="text" size="small"
            >编辑</el-button
          >
          <el-button @click="deleteData(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </page-table>
    <edit-data ref="editData" />
  </div>
</template>

<script>
import { newsIndex,newsDel } from "@/request/api";
import { checkPermission } from "@/utils/permissions";
import pageTable from "@/components/pageTable.vue";
import editData from "./components/editData"
export default {
  name: "user",
  components: {
      pageTable,
    editData
  },
  data() {
    return {
      rolesList: [], //角色列表
      userList: [], // 用户列表
      title: '',
      type:'',
      form: {
        username: "",
        member: "",
        time: "",
        id: "",
      },
      page: {
        //分页信息
        currentPage: 1, //当前页
        pageSize: 10, //每页条数
        total: 0, //总条数
      },
      tableSelectList: [], // 多选列表
    };
  },
  watch: {},
  created() {
    this.getUserList(); //获取用户列表
  },
  mounted() {},
  computed: {},
  methods: {
    checkPermission,
    // 切换分页
    changeCurrent(page, size) {
      this.page.currentPage = page;
      this.page.pageSize = size;
      this.getUserList();
    },
    
    getUserList() {
      let token = sessionStorage.getItem("token");
      this.token = token;
      console.log(token);
      let params = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        title: this.title,
        type:this.type,
      };
      newsIndex(params).then((res) => {
        console.log(res);
        this.userList = res.data.data;
        this.page.total = res.data.count;

        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      });
    },
    addData() {
      // 1:新增，2:编辑
      this.$refs.editData.show(1, {});
      },
    searchData() {
        this.page.currentPage = 1
        this.getUserList()
    },

    editData(type, row) {
      let rowData = row;
      this.$refs.editData.show(2, JSON.parse(JSON.stringify(rowData)));
    },
    deleteData(row) {
      this.$confirm("是否删除此数据？", "提示", {
        type: "warning",
      })
        .then(async () => {
          let params = {
            token: sessionStorage.getItem("token"),
            id: row.id,
          };
          newsDel(params).then((res) => {
            if (res.data.code == 200) {
              this.getUserList();
              this.$message.success("删除成功");
            } else {
              this.$message.error(res.data.msg);
              this.getUserList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
